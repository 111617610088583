import React, { useState } from 'react'
import './../css/AuditReport.css'
import * as jsonexport from 'jsonexport/dist'
import { getToken } from '../utils'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Spinner from 'react-bootstrap/Spinner'
import Audit from './Audit'
import ExternalPages from './ExternalPages'
import { Nav, NavItem, Form } from 'react-bootstrap'

const paginationLimit = 25
const dropdownTitle = {
  caseId: 'Case ID',
  customerId: 'Customer ID',
  assignee: 'Assignee',
  notLegalOwner: 'Not the Legal Owner',
}

const getAudit = async (caseId) => {
  const Authorization = await getToken()
  const response = await fetch(window._env_.REACT_APP_BASEURL + `/v1/ip/case/${caseId}/audit-report`, {
    headers: { Authorization },
  })
  const audit = await response.json()
  return audit.statusCode ? {} : audit
}

const getAudits = async (auditFrom, query, paginationQuery) => {
  let url = '/v1/ip'
  if (auditFrom === 'assignee') {
    url += `/assignee/${query}/`
  } else {
    url += `/customer/${query}/${auditFrom === 'notLegalOwner' ? 'notlegalowner/' : ''}`
  }
  url += `audit-report?${paginationQuery}`

  const Authorization = await getToken()
  const response = await fetch(window._env_.REACT_APP_BASEURL + url, {
    headers: { Authorization },
  })
  const audits = await response.json()
  return audits.statusCode ? [] : audits
}

const simplifyAudit = (originalAudit) => {
  const caseId = getCaseId(originalAudit)
  const simplifiedAudit = {
    'Pre-Publication Events': [],
    'Public Events': [],
    'Private Messages': [],
    'Trade Secret': [],
  }
  if (originalAudit === []) return { caseId: caseId }

  if (!!originalAudit.privateNotarisationEvents.length) {
    simplifiedAudit['Pre-Publication Events'] = originalAudit.privateNotarisationEvents
      .filter(({ offChainData }) => offChainData.eventId !== 'Trade Secret')
      .map(({ onChainData, offChainData }) => {
        const createdAt = onChainData.blockTimestamp || '1970-01-01T00:00:00.000Z'
        const transactionHash = onChainData.transactionHash
        const { caseId, eventId, payload } = offChainData
        return { [payload['WIPO event Title']]: { createdAt, data: { caseId, eventId, ...payload, transactionHash } } }
      })
  }

  if (!!originalAudit.privateNotarisationEvents.length) {
    simplifiedAudit['Trade Secret'] = originalAudit.privateNotarisationEvents
      .filter(({ offChainData }) => offChainData.eventId === 'Trade Secret')
      .map(({ onChainData, offChainData }) => {
        const createdAt = onChainData.blockTimestamp || '1970-01-01T00:00:00.000Z'
        const transactionHash = onChainData.transactionHash
        const { caseId, eventId, payload } = offChainData
        return { [payload['eventType']]: { createdAt, data: { caseId, eventId, ...payload, transactionHash } } }
      })
  }

  if (!!originalAudit.privateNotarisationEventsPublication.length) {
    simplifiedAudit['Public Events'] = originalAudit.privateNotarisationEventsPublication.map(
      ({ onChainData, offChainData }) => {
        const createdAt = (onChainData.data.payload.onChainData || {}).blockTimestamp || '1970-01-01T00:00:00.000Z'
        const transactionHash = onChainData.transactionHash
        const { caseId, eventId, payload } = offChainData
        return {
          [payload.offChainData.payload['WIPO event Title']]: {
            createdAt,
            data: { caseId, eventId, ...payload.offChainData.payload, transactionHash },
          },
        }
      }
    )
  }

  if (!!originalAudit.publishEvent.offChainData) {
    const { onChainData, offChainData } = originalAudit.publishEvent
    const createdAt = onChainData.blockTimestamp || '1970-01-01T00:00:00.000Z'
    const transactionHash = onChainData.transactionHash
    const { caseId, eventId, payload } = offChainData
    simplifiedAudit['Public Events'].push({
      [payload['WIPO event Title']]: { createdAt, data: { caseId, eventId, ...payload, transactionHash } },
    })
  }

  if (!!originalAudit.postPublicationEvents.length) {
    const postPublicationEvents = originalAudit.postPublicationEvents.map(({ onChainData, offChainData }) => {
      const createdAt = onChainData.blockTimestamp || '1970-01-01T00:00:00.000Z'
      const transactionHash = onChainData.transactionHash
      const { caseId, eventId, payload } = offChainData
      return { [payload['WIPO event Title']]: { createdAt, data: { caseId, eventId, ...payload, transactionHash } } }
    })
    simplifiedAudit['Public Events'].push(...postPublicationEvents)
  }

  if (!!originalAudit.privateMessages.length) {
    simplifiedAudit['Private Messages'] = originalAudit.privateMessages.map(({ onChainData, offChainData }) => {
      const createdAt = onChainData.blockTimestamp || '1970-01-01T00:00:00.000Z'
      const transactionHash = onChainData.transactionHash
      const { data } = offChainData
      const { caseId, eventId, messageType, privateMessage } = data
      const { event, message } = privateMessage

      return {
        [`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`]: {
          createdAt,
          data: {
            message,
            event: { caseId, sender: onChainData.sender, eventId, messageType, ...event.payload, transactionHash },
          },
        },
      }
    })
  }
  return { caseId: caseId, audit: simplifiedAudit }
}

const simplifyAudits = (audits) => audits.map((originalAudit) => simplifyAudit(originalAudit))

const getCaseId = ({ privateNotarisationEvents, publishEvent, privateMessages, postPublicationEvents }) => {
  if (privateNotarisationEvents.length) {
    return privateNotarisationEvents[0].offChainData.caseId
  }
  if (postPublicationEvents.length) {
    return postPublicationEvents[0].offChainData.caseId
  }
  if (privateMessages.length) {
    return privateMessages[0].offChainData.data.caseId
  }
  if (publishEvent.offChainData) {
    return publishEvent.offChainData.caseId
  }
  return 'case'
}

const AuditReport = () => {
  const [audits, updateAudit] = useState([])
  const [filter, updateFilter] = useState('')
  const [auditFrom, updateAuditFrom] = useState('caseId')
  const [status, updateStatus] = useState({ loading: false, valid: false })
  const [pagination, updatePagination] = useState({ previousUrl: '', nextUrl: '', total: 1, limit: 0, offset: 0 })
  const [activeCase, setActiveCase] = useState('1')

  const hasAudit = (audit) =>
    audit.audit['Pre-Publication Events'].length ||
    audit.audit['Public Events'].length ||
    audit.audit['Private Messages'].length ||
    audit.audit['Trade Secret'].length
  const hasAudits = audits.some(hasAudit)

  const fetchData = async (paginationQuery) => {
    try {
      updateStatus({ loading: true, valid: false })
      auditFrom === 'caseId' ? await fetchAudit() : await fetchAudits(filter.trim(), paginationQuery)
    } catch (e) {
      updateStatus({ loading: false, valid: false })
      console.error('an error occurred when retrieving audit', e)
    }
  }

  const fetchAudit = async () => {
    clearAudit(false)
    const resAudit = await getAudit(filter.trim())
    const simplifiedAudit = simplifyAudit(resAudit)

    if (!simplifiedAudit.audit) {
      updateAudit([])
      updateStatus({ loading: false, valid: false })
      return
    }

    updateAudit([simplifiedAudit])
    setActiveCase(simplifiedAudit.caseId)
    updateStatus({ loading: false, valid: hasAudit(simplifiedAudit) })
  }

  const fetchAudits = async (filter, paginationQuery) => {
    const {
      data: resAudits,
      pagination: { previousUrl, nextUrl, total, limit, offset },
    } = await getAudits(auditFrom, filter, paginationQuery)

    if (!resAudits.length) {
      updateAudit([])
      updateStatus({ loading: false, valid: false })
      return
    }

    const simplifiedAudits = simplifyAudits(resAudits)
    updateAudit(simplifiedAudits)
    setActiveCase(simplifiedAudits[0].caseId)
    const valid = !!simplifiedAudits.length

    updatePagination({ previousUrl, nextUrl, total, limit, offset })
    updateStatus({ loading: false, valid })
  }

  const download = async (type) => {
    const values = await getDowloadDataByType[type](audits || [])
    const data = new Blob([values], { type: `text/${type}` })
    const uRL = window.URL.createObjectURL(data)
    const tempLink = document.createElement('a')
    tempLink.href = uRL
    tempLink.setAttribute('download', `audit-${new Date().valueOf()}.${type}`)
    tempLink.click()
  }

  const getDowloadDataByType = {
    json: (audits) => JSON.stringify(audits),
    csv: async (audits) => {
      try {
        let csv = ''

        for (const audit of audits) {
          const csvAudit = await jsonexport(audit.audit)
          let eventId = ''

          const csvLines = csvAudit.split('\n').map((line) => {
            if (line.includes('.eventId')) {
              eventId = line.split(',')[1]
            }

            return `${audit.caseId},${eventId},${line}`
          })
          csvLines.unshift('CaseId,EventId,Field,Value')
          csv += csvLines.join('\n')
        }

        return csv
      } catch (err) {
        console.error(err)
        return ''
      }
    },
  }

  const clearAudit = (resetFilter = true) => {
    if (resetFilter) {
      updateFilter('')
    }

    updateAudit([])
    updateStatus({ ...status, valid: false })
    updatePagination({ previousUrl: '', nextUrl: '', total: 1, limit: 0, offset: 0 })
  }

  return (
    <div className="Audit">
      <div className="d-flex External">
        <div className="col-3">
          <ExternalPages />
        </div>
        <div className="col-6">
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              fetchData(`offset=0&limit=${paginationLimit}`)
            }}
          >
            <InputGroup style={{ justifyContent: 'center' }}>
              <InputGroup.Prepend>
                <DropdownButton variant="primary" title={dropdownTitle[auditFrom]}>
                  <Dropdown.Item onClick={() => updateAuditFrom('caseId')}>Case Id</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateAuditFrom('customerId')}>Customer Id</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateAuditFrom('assignee')}>Assignee</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateAuditFrom('notLegalOwner')}>Not the Legal Owner</Dropdown.Item>
                </DropdownButton>
              </InputGroup.Prepend>

              <div style={{ position: 'relative' }}>
                <FormControl
                  placeholder=""
                  value={filter}
                  onChange={(e) => updateFilter(e.target.value)}
                  style={{ paddingRight: '2em' }}
                />
                {filter && (
                  <span onClick={clearAudit} className="resetFilter">
                    x
                  </span>
                )}
              </div>

              <Button
                onClick={() => fetchData(`offset=0&limit=${paginationLimit}`)}
                disabled={filter.length === 0 || status.loading}
              >
                {status.loading && <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                {` Generate Audit`}
              </Button>
            </InputGroup>
          </Form>
        </div>
        <div className="col-3 buttons">
          <div className="col">
            {hasAudits && (
              <>
                <Button className="button" variant="primary" onClick={() => download('json')}>
                  {' '}
                  Download (.json){' '}
                </Button>
                <Button className="button" variant="primary" onClick={() => download('csv')}>
                  {' '}
                  Download (.csv){' '}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>

      {hasAudits && (
        <>
          {pagination.total > 1 && (
            <Nav style={{ alignItems: 'end' }}>
              <Form>
                <Form.Row className="align-items-center">
                  <Form.Label className="mr-sm-2" htmlFor="casesSelect">
                    List of Audits (records {pagination.offset} to{' '}
                    {pagination.offset + pagination.limit > pagination.total
                      ? pagination.total
                      : pagination.offset + pagination.limit}{' '}
                    of {pagination.total})
                  </Form.Label>

                  <Form.Control as="select" className="mr-sm-2" id="casesSelect" custom>
                    {audits.map(({ caseId }) => (
                      <option value={caseId} onClick={() => setActiveCase(caseId)} key={caseId}>
                        {caseId}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Row>
              </Form>

              {pagination.previousUrl !== '' && (
                <NavItem style={{ marginLeft: 10 }}>
                  <Button
                    variant="outline-secondary"
                    onClick={() => fetchData(pagination.previousUrl)}
                    disabled={status.loading}
                  >
                    &lt;&lt; Previous results
                  </Button>
                </NavItem>
              )}

              {pagination.nextUrl !== '' && (
                <NavItem style={{ marginLeft: 10 }}>
                  <Button
                    variant="outline-secondary"
                    onClick={() => fetchData(pagination.nextUrl)}
                    disabled={status.loading}
                  >
                    Next results &gt;&gt;
                  </Button>
                </NavItem>
              )}
            </Nav>
          )}

          <Audit data={audits.find((audit) => audit.caseId === activeCase)} />
        </>
      )}
    </div>
  )
}

export default AuditReport
