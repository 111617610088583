import React, { useState } from 'react'
import './../css/AuditReport.css'

const events = {
  changeOfLegalOwnership: 'Change in Ownership',
  assignmentInstructionFromCustomer: 'Assignment Instruction',
  AssignmentInstructionConfirmedByPTO: 'Assignment Instruction Confirmed',
  authorizationUpdate: 'Authorization Update',
}

const EventsRow = ({ data, cols }) => {
  const [collapse, updateCollapse] = useState(true)

  const eventName = Object.keys(data)[0]
  const eventId = data[eventName].data.eventId
  const updatedEventId = events[eventId] || eventId || eventName
  const date = new Date(data[eventName].createdAt)

  return (
    <>
      <tr className="header-row d-flex" onClick={() => updateCollapse(!collapse)}>
        {cols.map((col, i) => {
          switch (col) {
            case 'EventId':
              return (
                <td className="col" key={`eventId-${i}`} style={{ padding: '0 15px' }}>
                  {updatedEventId}
                </td>
              )
            case 'WIPO Status':
              return (
                <td className="col" key={`wipo-${i}`} style={{ padding: '0 15px' }}>
                  {eventName === 'undefined' ? '-' : eventName}
                </td>
              )
            case 'Timestamp':
              return (
                <td
                  className="col"
                  key={`timestamp-${i}`}
                  style={{ padding: '0 15px' }}
                >{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</td>
              )
            default:
              return null
          }
        })}
      </tr>

      {!collapse &&
        Object.keys(data[eventName].data).map((key) => {
          let value = data[eventName].data[key]

          if (!data[eventName].data[key]) {
            return null
          }

          if (key === 'usageHistory') {
            value = (
              <ul>
                {data[eventName].data[key].map((usage) => (
                  <li>
                    {usage.email} the {new Date(usage.timestamp).toLocaleString()}
                  </li>
                ))}
              </ul>
            )
          } else {
            if (typeof value !== 'string') {
              if (key === 'payload' && updatedEventId === 'Assignment Instruction Confirmed') {
                delete data[eventName].data[key].addressLineOne
                delete data[eventName].data[key].addressLineTwo
                delete data[eventName].data[key].jobId
              }

              value = Object.keys(data[eventName].data[key] || {}).map((objKey) => (
                <div>
                  {objKey}: {data[eventName].data[key][objKey]}
                </div>
              ))
            }

            if (key.toLowerCase() === 'documenturl') {
              value = <a href={value}>{value}</a>
            }

            if (key.toLowerCase() === 'apiauthorization') {
              return null
            }
          }

          return (
            <tr className={`event-row p-0 d-flex`} key={key}>
              <div className="col d-flex p-0 ">
                <div className="col-2 event-key">{key}</div>
                <div className="col event-value">{value}</div>
              </div>
            </tr>
          )
        })}
    </>
  )
}

export default EventsRow
