import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import EventRow from './EventRow'
import FilterRow from './FilterRow'

const matchingFilter = (filters) => (event) => {
  let match = true
  if (match && filters.caseId && event.data.payload.caseId !== filters.caseId) {
    match = false
  }
  if (match && filters.customerId && event.data.payload.customerId !== filters.customerId) {
    match = false
  }
  if (match && filters.documentId && event.data.payload.documentId !== filters.documentId) {
    match = false
  }
  if (match && filters.eventId && event.data.payload.eventId !== filters.eventId) {
    match = false
  }
  if (match && filters.versionId && event.data.payload.versionId !== filters.versionId) {
    match = false
  }
  if (
    match &&
    filters.docCreatedAt &&
    new Date(event.data.payload.docCreatedAt).toLocaleDateString() !== filters.docCreatedAt
  ) {
    match = false
  }
  return match
}

const EventsTable = ({ events }) => {
  const [renderedEvents, setRenderedEvents] = useState([])

  useEffect(() => setRenderedEvents(events), [events])

  const onFilterChanged = (activeFilters) => {
    setRenderedEvents(events.filter(matchingFilter(activeFilters)))
  }

  const renderTable = () => (
    <Table bordered hover className="table-striped mt-2">
      <thead className="thead-light">
        <tr className="d-flex p-0 m-0">
          <th className="col" scope="col">
            Case
          </th>
          <th className="col" scope="col">
            Event
          </th>
          <th className="col" scope="col">
            Customer
          </th>
          <th className="col" scope="col">
            Document
          </th>
          <th className="col" scope="col">
            Version
          </th>
          <th className="col" scope="col">
            Creation date
          </th>
        </tr>
        <FilterRow renderedEvents={renderedEvents} onFilterChanged={onFilterChanged} />
      </thead>
      <tbody>
        {renderedEvents.map((event, index) => (
          <EventRow event={event} key={index} />
        ))}
      </tbody>
    </Table>
  )
  return <div className="container-fluid mt-3">{renderTable()}</div>
}
export default EventsTable
