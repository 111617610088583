import { createSlice } from '@reduxjs/toolkit'

import { fetchAllAccessRequests, fetchAllTradeSecrets } from '../api'
import { loadingFailed, matchingFilter, startLoading } from './utils'

const initialState = {
  activeFilters: {
    accessStatus: 'approved',
  },
  error: null,
  events: [],
  fetchedAccessRequests: [],
  fetchedTradeSecrets: [],
  isLoading: false,
}

const hasRelatedScope = (accessRequest) => (tradeSecret) => {
  const arPayload = accessRequest.data.payload
  const tsPayload = tradeSecret.data.payload

  if (arPayload.scope.documentId && tsPayload.documentId !== arPayload.scope.documentId) {
    return false
  }
  if (tsPayload.caseId !== arPayload.scope.caseId) {
    return false
  }

  return true
}

const accessRequestsSlice = createSlice({
  name: 'myAccessRequests',
  initialState,
  reducers: {
    applyActiveFilters: (state) => {
      const filteredEvents = state.fetchedEvents.filter(matchingFilter(state.activeFilters))
      state.events = filteredEvents.length > 0 ? filteredEvents : state.fetchedEvents
    },
    filter: (state, action) => {
      state.events = state.fetchedEvents.filter(matchingFilter(action.payload))
      state.activeFilters = action.payload
    },

    getMineFailure: loadingFailed,
    getMineStart: startLoading,
    getMineSuccess: (state, action) => {
      state.isLoading = false
      state.error = null
      const { accessRequests, tradeSecrets } = action.payload
      state.fetchedEvents = accessRequests.map((accessRequest) => {
        const relatedTradeSecrets = tradeSecrets.filter(hasRelatedScope(accessRequest))
        accessRequest.data.payload = {
          ...accessRequest.data.payload,
          documents: [],
        }
        relatedTradeSecrets.forEach((ts) =>
          accessRequest.data.payload.documents.push({
            url: ts.data.payload.documentURL,
            documentId: ts.data.payload.documentId,
            versionId: ts.data.payload.versionId,
            hash: ts.data.payload.documentHash,
          })
        )
        return accessRequest
      })
    },

    resetEvents: (state) => {
      state.events = []
    },
  },
})

export const { filter, applyActiveFilters } = accessRequestsSlice.actions
const { getMineFailure, getMineStart, getMineSuccess } = accessRequestsSlice.actions

export default accessRequestsSlice.reducer

export const getMine = () => async (dispatch) => {
  try {
    dispatch(getMineStart())
    const resAccessRequests = await fetchAllAccessRequests()
    if (resAccessRequests.isError) {
      return dispatch(getMineFailure(resAccessRequests.data))
    }
    const resTradeSecrets = await fetchAllTradeSecrets()
    if (resTradeSecrets.isError) {
      return dispatch(getMineFailure(resTradeSecrets.data))
    }

    dispatch(getMineSuccess({ accessRequests: resAccessRequests.data.items, tradeSecrets: resTradeSecrets.data.items }))
    dispatch(applyActiveFilters())
  } catch (error) {
    dispatch(getMineFailure(error))
  }
}
