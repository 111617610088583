import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'

const RowActions = ({ request, onClickUserApproval, onClickDomainApproval, onClickRevocation }) => {
  const [tooltipApproveEmailOpen, setTooltipApproveEmailOpen] = useState(false)
  const toggleTooltipApproveEmail = () => setTooltipApproveEmailOpen(!tooltipApproveEmailOpen)
  const [tooltipApproveDomainOpen, setTooltipApproveDomainOpen] = useState(false)
  const toggleTooltipApproveDomain = () => setTooltipApproveDomainOpen(!tooltipApproveDomainOpen)
  const [tooltipRevokeOpen, setTooltipRevokeOpen] = useState(false)
  const toggleTooltipRevoke = () => setTooltipRevokeOpen(!tooltipRevokeOpen)

  const { accessStatus } = request.data.payload
  const requestId = request.id.replace(/[^\w\d]/g, '')
  const emailApprovalId = `email${requestId}`
  const domainApprovalId = `domain${requestId}`
  const domainRevocationId = `revoke${requestId}`

  const renderApprovalActions = () => {
    if (accessStatus !== 'requested') {
      return null
    }
    return (
      <>
        <div id={emailApprovalId} onClick={() => onClickUserApproval(request)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-person-check text-success"
            viewBox="0 0 16 16"
          >
            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
            <path
              fillRule="evenodd"
              d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>
        <Tooltip
          placement="top"
          isOpen={tooltipApproveEmailOpen}
          target={emailApprovalId}
          toggle={toggleTooltipApproveEmail}
        >
          Approve at user level
        </Tooltip>
        <div id={domainApprovalId} onClick={() => onClickDomainApproval(request)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-cloud-check text-success"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
            />
            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
          </svg>
        </div>
        <Tooltip
          placement="top"
          isOpen={tooltipApproveDomainOpen}
          target={domainApprovalId}
          toggle={toggleTooltipApproveDomain}
        >
          Approve at domain level
        </Tooltip>
      </>
    )
  }

  const renderRevokeAction = () => {
    if (accessStatus !== 'approved') {
      return null
    }
    return (
      <>
        <div id={domainRevocationId} onClick={() => onClickRevocation(request)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-cloud-slash text-danger"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M3.112 5.112a3.125 3.125 0 0 0-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11l-1-1H3.781C2.231 12 1 10.785 1 9.318c0-1.365 1.064-2.513 2.46-2.666l.446-.05v-.447c0-.075.006-.152.018-.231l-.812-.812zm2.55-1.45l-.725-.725A5.512 5.512 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 0 1-1.516 2.711l-.733-.733C14.498 11.378 15 10.626 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3c-.875 0-1.678.26-2.339.661z"
            />
            <path d="M13.646 14.354l-12-12 .708-.708 12 12-.707.707z" />
          </svg>
        </div>
        <Tooltip placement="top" isOpen={tooltipRevokeOpen} target={domainRevocationId} toggle={toggleTooltipRevoke}>
          Revoke the request
        </Tooltip>
      </>
    )
  }

  const renderActionIcons = () => (
    <>
      {renderApprovalActions()}
      {renderRevokeAction()}
    </>
  )

  return <>{renderActionIcons()}</>
}

export default RowActions
