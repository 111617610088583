import React from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'reactstrap'

import TableHead from './TableHead'
import TableRow from './TableRow'

const AccessRequestsTable = () => {
  const { events } = useSelector((state) => state.myAccessRequests)

  const renderRow = (event) => <TableRow key={event.id} request={event} />

  return (
    <div className="container-fluid">
      <Table bordered hover className="table-striped mt-3">
        <TableHead />
        <tbody>{events.map(renderRow)}</tbody>
      </Table>
    </div>
  )
}

export default AccessRequestsTable
