import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Button, FormGroup, Label, Input, FormFeedback, Spinner } from 'reactstrap'

import './../../../../../css/TradeSecrets.css'
import { postTradeSecret } from '../../../api'
import ResponseDisplay from './ResponseDisplay'

const TradeSecretCreateForm = () => {
  const [pending, setPending] = useState(false)
  const [response, setResponse] = useState()

  const { register, handleSubmit, watch, errors, reset } = useForm()
  const onSubmit = async (data) => {
    setPending(true)
    const payload = {
      ...data,
      file: data.file[0],
    }
    setResponse(
      await postTradeSecret(payload)
        .then((response) => {
          reset()
          return response
        })
        .finally(() => setPending(false))
    )
  }

  return (
    <div className="TradeSecretCreateForm container">
      <Col sm={{ size: 8, offset: 2 }}>
        {pending ? <Spinner color="primary" /> : ''}
        {response ? <ResponseDisplay response={response} /> : ''}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Label for="customerId" sm={{ size: 3 }}>
              Customer ID
            </Label>
            <Col>
              <Input
                valid={!!watch('customerId')}
                invalid={!!errors.customerId}
                name="customerId"
                id="customerId"
                placeholder="customer id"
                innerRef={register({ required: true })}
              />
              <FormFeedback>{errors.customerId && <span>This field is required</span>}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="caseId" sm={{ size: 3 }}>
              Case ID
            </Label>
            <Col>
              <Input
                valid={!!watch('caseId')}
                invalid={!!errors.caseId}
                name="caseId"
                id="caseId"
                placeholder="case id"
                innerRef={register({ required: true })}
              />
              <FormFeedback>{errors.caseId && 'This field is required'}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="documentId" sm={{ size: 3 }}>
              Document ID
            </Label>
            <Col>
              <Input
                valid={!!watch('documentId')}
                invalid={!!errors.documentId}
                name="documentId"
                id="documentId"
                placeholder="document id"
                innerRef={register({ required: true })}
              />
              <FormFeedback>{errors.documentId && <span>This field is required</span>}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="file" sm={{ size: 3 }}>
              File
            </Label>
            <Col>
              <Input
                valid={!!watch('file')}
                invalid={!!errors.file}
                type="file"
                name="file"
                id="file"
                innerRef={register({ required: true })}
              />
              <FormFeedback>{errors.file && <span>This field is required</span>}</FormFeedback>
            </Col>
          </FormGroup>

          <Button color="primary" type="submit" disabled={pending}>
            Create Trade Secret
          </Button>
        </form>
      </Col>
    </div>
  )
}

export default TradeSecretCreateForm
