import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import './../../../css/TradeSecrets.css'
import TradeSecretCreateForm from './trade-secrets/Create/CreateForm'
import classnames from 'classnames'
import TradeSecretsView from './trade-secrets/View/View'
import AccessRequestsView from './access-requests/View'

const tabs = {
  '#new-trade-secret': '1',
  '#trade-secrets': '2',
  '#acccess-requests': '3',
}

const TradeSecretsHolder = (props) => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = (tab) => () => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => setActiveTab(tabs[props.location.hash] || '1'), [props.location.hash])

  return (
    <div className="TradeSecretsHolder">
      <Nav tabs className="nav-pills">
        <NavItem>
          <NavLink href="#new-trade-secret" className={classnames({ active: activeTab === '1' })} onClick={toggle('1')}>
            New Trade Secret
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#trade-secrets" className={classnames({ active: activeTab === '2' })} onClick={toggle('2')}>
            Trade Secrets
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#acccess-requests" className={classnames({ active: activeTab === '3' })} onClick={toggle('3')}>
            Access Requests
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <TradeSecretCreateForm />
        </TabPane>
        <TabPane tabId="2">
          <TradeSecretsView activeTab={activeTab} />
        </TabPane>
        <TabPane tabId="3">
          <AccessRequestsView activeTab={activeTab} />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default TradeSecretsHolder
