import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Col, Button, FormGroup, Label, Input, FormFeedback, Spinner, Toast, ToastHeader, ToastBody } from 'reactstrap'

import './CreateAccessRequest.css'
import { postAccessRequest } from '../../api'
import ErrorToast from '../../holder/components/ErrorToast'

const CreateAccessRequestForm = () => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState()
  const [showSuccess, setShowSuccess] = useState(false)

  const { register, handleSubmit, watch, errors, reset } = useForm()
  const onSubmit = async (data) => {
    setError()
    setPending(true)
    await postAccessRequest(data)
      .then((response) => {
        if (response.isError) {
          const message =
            response.data.message === 'jwt malformed'
              ? 'AuthToken is invalid, please ask a new one'
              : response.data.message
          return setError({ message })
        }

        setShowSuccess(true)
        reset()
        return response
      })
      .catch((error) => setError(error))
      .finally(() => setPending(false))
  }

  const renderSuccess = () => {
    if (!showSuccess) {
      return null
    }
    return (
      <div className="p-3 bg-success my-2 rounded">
        <Toast>
          <ToastHeader icon="success">Thank you</ToastHeader>
          <ToastBody>Your request was sent, you will receive an email when it is approved.</ToastBody>
        </Toast>
      </div>
    )
  }

  return (
    <div className="CreateAccessRequestForm container">
      <Col sm={{ size: 8, offset: 2 }}>
        {pending ? <Spinner color="primary" /> : ''}
        <ErrorToast error={error} />
        {renderSuccess()}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Label for="authToken" sm={{ size: 3 }}>
              Login Code
            </Label>
            <Col>
              <Input
                valid={!!watch('authToken')}
                invalid={!!errors.authToken}
                name="authToken"
                id="authToken"
                placeholder="Login Code received by email"
                innerRef={register({ required: true })}
              />
              <FormFeedback>{errors.authToken && 'This field is required'}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="caseId" sm={{ size: 3 }}>
              Case ID
            </Label>
            <Col>
              <Input
                valid={!!watch('caseId')}
                invalid={!!errors.caseId}
                name="caseId"
                id="caseId"
                placeholder="case id"
                innerRef={register({ required: true })}
              />
              <FormFeedback>{errors.caseId && 'This field is required'}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="documentId" sm={{ size: 3 }}>
              Document ID <span className="optional">Optional</span>
            </Label>
            <Col>
              <Input
                valid={!!watch('documentId')}
                invalid={!!errors.documentId}
                name="documentId"
                id="documentId"
                placeholder="document id"
                innerRef={register({ required: false })}
              />
            </Col>
          </FormGroup>

          <Button color="primary" type="submit" disabled={pending}>
            Request Access
          </Button>
        </form>
      </Col>
    </div>
  )
}

export default CreateAccessRequestForm
