import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AzureAD } from 'react-aad-msal'

import AuthProvider from './AuthProvider'
import App from './App'
import store from './store/store'
import 'bootstrap/dist/css/bootstrap.min.css'

console.log(window._env_)

ReactDOM.render(
  <Provider store={store}>
    <AzureAD provider={AuthProvider} forceLogin={true}>
      <App />
    </AzureAD>
  </Provider>,
  document.getElementById('root')
)
