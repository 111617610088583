import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'

const renderSelectOption = (values) => {
  return values.map((value) => (
    <option key={value} value={value}>
      {value}
    </option>
  ))
}

const uniq = (values) => Array.from(new Set(values)).sort()

const FilterRow = ({ renderedEvents, onFilterChanged }) => {
  const [dedupeCaseIds, setDedupeCaseIds] = useState([])
  const [dedupeCustomerIds, setDedupeCustomerIds] = useState([])
  const [dedupeDocumentIds, setDedupeDocumentIds] = useState([])
  const [dedupeEventIds, setDedupeEventIds] = useState([])
  const [dedupeVersionIds, setDedupeVersionIds] = useState([])
  const [dedupeCreationDates, setDedupeCreationDates] = useState([])
  const [activeFilters, setActiveFilters] = useState({})

  useEffect(() => {
    onFilterChanged(activeFilters)
  }, [activeFilters])

  useEffect(() => {
    setDedupeCaseIds(uniq(renderedEvents.map((event) => event.data.payload.caseId)))
    setDedupeCustomerIds(uniq(renderedEvents.map((event) => event.data.payload.customerId)))
    setDedupeDocumentIds(uniq(renderedEvents.map((event) => event.data.payload.documentId)))
    setDedupeEventIds(uniq(renderedEvents.map((event) => event.data.payload.eventId)))
    setDedupeVersionIds(uniq(renderedEvents.map((event) => event.data.payload.versionId)))
    setDedupeCreationDates(
      uniq(renderedEvents.map((event) => new Date(event.data.payload.docCreatedAt).toLocaleDateString()))
    )
  }, [renderedEvents])

  const renderSelect = ({ filterProp, ids }) => (
    <Input
      className="p-0 input__lessheight"
      type="select"
      name="select"
      id={`${filterProp}Select`}
      onChange={(event) => setActiveFilters({ ...activeFilters, [filterProp]: event.target.value })}
    >
      <option key={''} value={null}></option>
      {renderSelectOption(ids)}
    </Input>
  )

  return (
    <tr className="d-flex p-0 m-0 table__filterrow">
      <th className="col p-1" scope="col">
        {renderSelect({ filterProp: 'caseId', ids: dedupeCaseIds })}
      </th>
      <th className="col p-1" scope="col">
        {renderSelect({ filterProp: 'eventId', ids: dedupeEventIds })}
      </th>
      <th className="col p-1" scope="col">
        {renderSelect({ filterProp: 'customerId', ids: dedupeCustomerIds })}
      </th>
      <th className="col p-1" scope="col">
        {renderSelect({ filterProp: 'documentId', ids: dedupeDocumentIds })}
      </th>
      <th className="col p-1" scope="col">
        {renderSelect({ filterProp: 'versionId', ids: dedupeVersionIds })}
      </th>
      <th className="col p-1" scope="col">
        {renderSelect({ filterProp: 'docCreatedAt', ids: dedupeCreationDates })}
      </th>
    </tr>
  )
}

export default FilterRow
