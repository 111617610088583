import React, { useState } from 'react'
import Chevron from '../../../Chevron'
import RowActions from './RowActions'
import SubRow from './SubRow'

const TableRow = ({ request, onClickUserApproval, onClickDomainApproval, onClickRevocation }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const { scope, accessStatus } = request.data.payload

  const renderRow = () => (
    <tr key={request.id} className="d-flex p-0 m-0">
      <td onClick={toggle} className="col-2">
        <Chevron isOpen={isOpen} />
        <span>{new Date(request.createdAt).toLocaleDateString()}</span>
      </td>
      <td onClick={toggle} className="col-4">
        {scope.caseId}
      </td>
      <td onClick={toggle} className="col-4">
        {scope.documentId || '*'}
      </td>
      <td onClick={toggle} className="col-2 d-flex">
        <span className="flex-grow-1">{accessStatus}</span>
        <div onClick={(event) => event.stopPropagation()} className="action">
          <RowActions
            key={request.id}
            request={request}
            onClickUserApproval={onClickUserApproval}
            onClickDomainApproval={onClickDomainApproval}
            onClickRevocation={onClickRevocation}
          />
        </div>
      </td>
    </tr>
  )

  const renderSubRow = () => (isOpen ? <SubRow request={request} /> : null)

  return (
    <>
      {renderRow()}
      {renderSubRow()}
    </>
  )
}

export default TableRow
