import React from 'react'
import FilterRow from './FilterRow'

const TableHead = () => (
  <thead className="thead-light">
    <tr className="d-flex p-0 m-0">
      <th className="col-2" scope="col">
        Date
      </th>
      <th className="col-3" scope="col">
        Case
      </th>
      <th className="col-2" scope="col">
        Event
      </th>
      <th className="col-2" scope="col">
        Document
      </th>
      <th className="col-1" scope="col">
        Version
      </th>
      <th className="col-2" scope="col">
        Subject
      </th>
    </tr>
    <FilterRow />
  </thead>
)

export default TableHead
