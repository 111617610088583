import React, { useState } from 'react'
import './../css/AuditReport.css'

const MessageRow = ({ data }) => {
  const [collapse, updateCollapse] = useState(true)
  const open = () => {
    updateCollapse(!collapse)
  }
  const eventName = Object.keys(data)[0]
  const date = new Date(data[eventName].createdAt)

  return (
    <>
      <tr className="header-row d-flex" onClick={open}>
        <td className="col" style={{ padding: '0 15px' }}>
          {data[eventName].data.event.messageType}{' '}
        </td>
        <td
          className="col"
          style={{ padding: '0 15px' }}
        >{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`}</td>
      </tr>

      {!collapse && (
        <>
          <tr className="sub-header-row">
            <td>Message</td>
          </tr>
          {Object.keys(data[eventName].data['message']).map((attribute) => (
            <tr key={attribute} className={`event-row p-0 d-flex test`}>
              <div className="col d-flex p-0 ">
                <div className="col-2 event-key">{attribute}</div>
                <div className="col event-value">{data[eventName].data['message'][attribute]}</div>
              </div>
            </tr>
          ))}

          <tr className="sub-header-row">
            <td>Event</td>
          </tr>
          {Object.keys(data[eventName].data['event']).map((attribute) => {
            let value = data[eventName].data['event'][attribute]
            if (typeof value !== 'string') {
              value = Object.keys(data[eventName].data['event'][attribute]).map((objKey) => (
                <div>
                  {objKey}: {data[eventName].data['event'][attribute][objKey]}
                </div>
              ))
            }

            if (attribute.toLowerCase() === 'documenturl') {
              value = <a href={value}>{value}</a>
            }

            if (attribute.toLowerCase() === 'apiauthorization') {
              return null
            }

            return (
              <tr key={attribute} className={`event-row p-0 d-flex test`}>
                <div className="col d-flex p-0 ">
                  <div className="col-2 event-key">{attribute}</div>
                  <div className="col event-value">{value}</div>
                </div>
              </tr>
            )
          })}
        </>
      )}
    </>
  )
}

export default MessageRow
