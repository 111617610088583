export function startLoading(state) {
  state.isLoading = true
}

export function loadingFailed(state, action) {
  state.isLoading = false
  state.error = action.payload
}

const doesMatch = (filters, event, propName) => {
  if (!filters[propName]) {
    return true
  }
  if (filters[propName] === '*' && !event.data.payload.scope[propName]) {
    return true
  }

  return event.data.payload.scope[propName] === filters[propName]
}

export const matchingFilter = (filters) => (event) => {
  let match = true
  if (match && filters.updatedAt && new Date(event.updatedAt).toLocaleDateString() !== filters.updatedAt) {
    match = false
  }
  if (match && filters.caseId && event.data.payload.scope.caseId !== filters.caseId) {
    match = false
  }
  if (match && filters.eventId && event.data.payload.scope.eventId !== filters.eventId) {
    match = false
  }
  if (match) {
    match = doesMatch(filters, event, 'documentId')
  }
  if (match) {
    match = doesMatch(filters, event, 'versionId')
  }
  if (match && filters.accessStatus && event.data.payload.accessStatus !== filters.accessStatus) {
    match = false
  }
  if (match && filters.subject && event.data.payload.subject !== filters.subject) {
    match = false
  }
  return match
}
