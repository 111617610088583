import React from 'react'
import { Table } from 'reactstrap'
import EventsRow from './EventsRow'
import './../css/AuditReport.css'

const EventsTable = ({ data, title, cols }) => {
  if (data[title].length === 0) {
    return null
  }

  return (
    <div>
      <h5>{title}</h5>

      <Table className="event-table">
        <thead>
          <tr className="font-weight-bold event-name d-flex">
            {cols.map((col) => (
              <th className="col" key={`${title}-${col}`} style={{ padding: '0 15px', border: 0 }}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data[title].map((event, index) => (
            <EventsRow key={`${title}-${index}`} data={event} cols={cols} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}
export default EventsTable
