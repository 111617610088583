import React, { useState } from 'react'
import { Table } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import {
  approveAccessRequestForUser,
  approveAccessRequestForDomain,
  revokeAccessRequestForDomain,
} from '../../../slices/accessRequestsSlices'
import ConfirmationModal from './ConfirmationModal'
import TableHead from './TableHead'
import TableRow from './TableRow'

const clickedActions = {
  userLevelApproval: 'userLevelApproval',
  domainLevelApproval: 'domainLevelApproval',
  revocation: 'revocation',
}
const emailDomain = (email = '') => email.split('@').pop()
const modalContextByAction = {
  [clickedActions.userLevelApproval]: ({ subject }) => ({
    title: 'Approve at user level ?',
    body: (
      <p>
        Are you sure you want to approve this access request for the <b>{subject}</b> email only ?
      </p>
    ),
  }),
  [clickedActions.domainLevelApproval]: ({ subject }) => ({
    title: 'Approve at domain level ?',
    body: (
      <p>
        Are you sure you want to approve this access request for the whole <b>@{emailDomain(subject)}</b> domain ?
      </p>
    ),
  }),
  [clickedActions.revocation]: ({ level = 'email', subject }) => {
    const body =
      level === 'domain' ? (
        <p>
          Are you sure you want to revoke this access request for the whole <b>@{emailDomain(subject)}</b> domain ?
        </p>
      ) : (
        <p>
          Are you sure you want to revoke this access request for subject <b>{subject}</b> domain ?
        </p>
      )
    return {
      title: `Revoke at ${level} level ?`,
      body,
    }
  },
}

const AccessRequestsTable = () => {
  const [modalContext, setModalContext] = useState({})
  const dispatch = useDispatch()
  const { events } = useSelector((state) => state.accessRequests)

  const closeModal = () => {
    setModalContext({
      ...modalContext,
      isOpen: false,
    })
  }

  const postAccessUpdate = async (action, payload) => {
    if (action === clickedActions.userLevelApproval) {
      return dispatch(approveAccessRequestForUser(payload))
    }
    if (action === clickedActions.domainLevelApproval) {
      return dispatch(approveAccessRequestForDomain(payload))
    }
    return dispatch(revokeAccessRequestForDomain(payload))
  }

  const confirmAction = (action, payload) => async () => {
    closeModal()
    await postAccessUpdate(action, payload)
  }

  const openModal = (action) => (request) => {
    const { accessRequestId, caseId, eventId, level, subject } = request.data.payload
    let content
    if (action === clickedActions.userLevelApproval) {
      content = modalContextByAction.userLevelApproval({ subject })
    } else if (action === clickedActions.domainLevelApproval) {
      content = modalContextByAction.domainLevelApproval({ subject })
    } else if (action === clickedActions.revocation) {
      content = modalContextByAction.revocation({ level, subject })
    }
    setModalContext({
      isOpen: true,
      title: content.title,
      body: content.body,
      onConfirm: confirmAction(action, {
        caseId,
        eventId,
        accessRequestId,
        level,
      }),
      onCancel: closeModal,
    })
  }

  const renderRow = (event) => (
    <TableRow
      key={event.id}
      request={event}
      onClickUserApproval={openModal(clickedActions.userLevelApproval)}
      onClickDomainApproval={openModal(clickedActions.domainLevelApproval)}
      onClickRevocation={openModal(clickedActions.revocation)}
    />
  )

  return (
    <div className="container-fluid">
      {modalContext.isOpen ? (
        <ConfirmationModal
          isOpen={modalContext.isOpen}
          title={modalContext.title}
          body={modalContext.body}
          onConfirm={modalContext.onConfirm}
          onCancel={modalContext.onCancel}
        />
      ) : null}

      <Table bordered hover className="table-striped mt-3">
        <TableHead />
        <tbody>{events.map(renderRow)}</tbody>
      </Table>
    </div>
  )
}

export default AccessRequestsTable
