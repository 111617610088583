import React from 'react'
import './../css/ExternalPages.css'
import Button from 'react-bootstrap/Button'
const ExternalButton = ({ link, text }) => (
  <div className="col">
    <Button className="button" onClick={() => window.open(link, '_blank')}>
      {text}
    </Button>
  </div>
)
const externalPages = [
  { link: window._env_.REACT_APP_BLOCKCHAIN_EXPLORER, title: 'Blockchain Monitor' },
  { link: window._env_.REACT_APP_BLOCKCHAIN_MONITOR, title: 'Blockchain Explorer' },
]

const ExternalPages = () => {
  return (
    <div className="buttons">
      {externalPages.map(({ link, title }, index) => (
        <ExternalButton link={link} key={index} text={title} />
      ))}
    </div>
  )
}

export default ExternalPages
