import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import Chevron from '../../../../Chevron'

const EventRow = ({ event }) => {
  const {
    caseId,
    customerId,
    documentId,
    eventId,
    versionId,
    docCreatedAt,
    docUpdatedAt,
    documentURL,
    documentHash,
  } = event.data.payload

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const renderDetails = () => (
    <tr className="d-flex p-0 m-0">
      <td className="col p-0 m-0">
        <Col>
          <Row>
            <Col className="col-2 ts-event-key">customerId</Col>
            <Col className="col-10 ts-event-value">{customerId}</Col>
          </Row>
          <Row>
            <Col className="col-2 ts-event-key">caseId</Col>
            <Col className="col-10 ts-event-value">{caseId}</Col>
          </Row>
          <Row>
            <Col className="col-2 ts-event-key">eventId</Col>
            <Col className="col-10 ts-event-value">{eventId}</Col>
          </Row>
          <Row>
            <Col className="col-2 ts-event-key">docCreatedAt</Col>
            <Col className="col-10 ts-event-value">{docCreatedAt}</Col>
          </Row>
          <Row>
            <Col className="col-2 ts-event-key">docUpdatedAt</Col>
            <Col className="col-10 ts-event-value">{docUpdatedAt}</Col>
          </Row>
          <Row>
            <Col className="col-2 ts-event-key">documentURL</Col>
            <Col className="col-10 ts-event-value">
              <a rel="noopener noreferrer" target="_blank" href={documentURL}>
                {documentURL}
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="col-2 ts-event-key">documentHash</Col>
            <Col className="col-10 ts-event-value">{documentHash}</Col>
          </Row>
        </Col>
      </td>
    </tr>
  )

  return (
    <>
      <tr className="d-flex p-0 m-0" onClick={toggle}>
        <td className="col">
          <Chevron isOpen={isOpen} />
          {caseId}
        </td>
        <td className="col">{eventId}</td>
        <td className="col">{customerId}</td>
        <td className="col">{documentId}</td>
        <td className="col">{versionId}</td>
        <td className="col">{new Date(docCreatedAt).toLocaleDateString()}</td>
      </tr>
      {isOpen ? renderDetails() : null}
    </>
  )
}

export default EventRow
