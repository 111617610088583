import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'

import './../../../css/TradeSecrets.css'
import AccessRequestsView from './my-access-requests/List/View'
import GetAuthTokenForm from './GetAuthTokenForm'
import CreateAccessRequestForm from './create-access-request/View'

const tabs = {
  '#log-in': '1',
  '#new-access-request': '2',
  '#acccess-requests': '3',
}

const TradeSecretsConsumer = (props) => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = (tab) => () => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => setActiveTab(tabs[props.location.hash] || '1'), [props.location.hash])

  return (
    <div className="TradeSecretsConsumer">
      <Nav tabs className="nav-pills">
        <NavItem>
          <NavLink href="#log-in" className={classnames({ active: activeTab === '1' })} onClick={toggle('1')}>
            Log In
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#new-access-request"
            className={classnames({ active: activeTab === '2' })}
            onClick={toggle('2')}
          >
            Request Access
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#acccess-requests" className={classnames({ active: activeTab === '3' })} onClick={toggle('3')}>
            View documents
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <GetAuthTokenForm />
        </TabPane>
        <TabPane tabId="2">
          <CreateAccessRequestForm />
        </TabPane>
        <TabPane tabId="3">
          <AccessRequestsView activeTab={activeTab} />
        </TabPane>
      </TabContent>
    </div>
  )
}

export default TradeSecretsConsumer
