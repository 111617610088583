import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import ErrorToast from '../../../holder/components/ErrorToast'
import { getMine } from '../../../slices/myAccessRequestsSlices'
import AccessRequestsTable from './components/Table'

const AccessRequestsList = ({ activeTab }) => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector((state) => state.myAccessRequests)

  useEffect(() => {
    if (activeTab === '3') dispatch(getMine())
  }, [activeTab])

  return (
    <div className="AccessRequestsView mt-3 px-xl-5">
      <ErrorToast error={error} />
      {isLoading ? (
        <Spinner
          color="primary"
          as="span"
          animation="grow"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
          aria-hidden="true"
          className="mt-3"
        />
      ) : (
        <AccessRequestsTable />
      )}
    </div>
  )
}

export default AccessRequestsList
