import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import './TradeSecretsView.css'
import EventsTable from './components/EventsTable'
import ErrorToast from '../../components/ErrorToast'
import { getAll } from '../../../slices/tradeSecretsSlices'

const TradeSecretsView = ({ activeTab }) => {
  const dispatch = useDispatch()
  const { isLoading, error, events } = useSelector((state) => state.tradeSecrets)

  useEffect(() => {
    if (activeTab === '2') dispatch(getAll())
  }, [activeTab])

  return (
    <div className="TradeSecretsView mt-3 px-xl-5">
      <ErrorToast error={error} />
      {isLoading ? (
        <Spinner
          color="primary"
          as="span"
          animation="grow"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
          aria-hidden="true"
          className="mt-3"
        />
      ) : (
        <EventsTable events={events} />
      )}
    </div>
  )
}

export default TradeSecretsView
