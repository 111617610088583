import React from 'react'
import { Container, Col, Row } from 'reactstrap'

const SubRow = ({ request }) => {
  const { accessRequestId, accessStatus, level, subject, caseId, eventId } = request.data.payload

  return (
    <tr className="d-flex p-0 m-0 sub-row">
      <td className="col p-0 m-0">
        <Container>
          <Row>
            <Col className="col-2 sub-row__key">caseId</Col>
            <Col className="col-10 sub-row__value">{caseId}</Col>
          </Row>
          <Row>
            <Col className="col-2 sub-row__key">eventId</Col>
            <Col className="col-10 sub-row__value">{eventId}</Col>
          </Row>
          <Row>
            <Col className="col-2 sub-row__key">subject</Col>
            <Col className="col-10 sub-row__value">{subject}</Col>
          </Row>
          {level ? (
            <Row>
              <Col className="col-2 sub-row__key">level</Col>
              <Col className="col-10 sub-row__value">{level}</Col>
            </Row>
          ) : null}
          <Row>
            <Col className="col-2 sub-row__key">accessStatus</Col>
            <Col className="col-10 sub-row__value">{accessStatus}</Col>
          </Row>
          <Row>
            <Col className="col-2 sub-row__key">createdAt</Col>
            <Col className="col-10 sub-row__value">{new Date(request.createdAt).toLocaleString()}</Col>
          </Row>
          <Row>
            <Col className="col-2 pb-3 sub-row__key">accessRequestId</Col>
            <Col className="col-10 sub-row__value">{accessRequestId}</Col>
          </Row>
        </Container>
      </td>
    </tr>
  )
}
export default SubRow
