import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap'

import { filter } from '../../../slices/accessRequestsSlices'

const renderSelectOptions = (values) =>
  values.map((value = '*') => (
    <option key={value} value={value}>
      {value}
    </option>
  ))

const uniqDefined = (values) =>
  Array.from(new Set(values))
    .filter((value) => !!value)
    .sort()
const uniq = (values) => Array.from(new Set(values)).sort()

const FilterRow = () => {
  const dispatch = useDispatch()
  const { error, events } = useSelector((state) => state.accessRequests)

  const [filters, setFilters] = useState({})
  const [dedupeDates, setDedupeDates] = useState([])
  const [dedupeCaseIds, setDedupeCaseIds] = useState([])
  const [dedupeDocumentIds, setDedupeDocumentIds] = useState([])
  const [dedupeStatus, setDedupeStatus] = useState([])

  useEffect(() => {
    if (!error) {
      dispatch(filter(filters))
    }
  }, [filters, error])

  useEffect(() => {
    setDedupeDates(uniqDefined(events.map((event) => new Date(event.updatedAt).toLocaleDateString())))
    setDedupeCaseIds(uniqDefined(events.map((event) => event.data.payload.scope.caseId)))
    setDedupeDocumentIds(uniq(events.map((event) => event.data.payload.scope.documentId)))
    setDedupeStatus(uniqDefined(events.map((event) => event.data.payload.accessStatus)))
  }, [events])

  const renderSelect = ({ filterProp, ids }) => (
    <Input
      className="p-0 input__lessheight"
      type="select"
      name="select"
      id={`${filterProp}Select`}
      onChange={(event) => setFilters({ ...filters, [filterProp]: event.target.value })}
    >
      <option key={`${filterProp}everyone`} value={null} />
      {renderSelectOptions(ids)}
    </Input>
  )

  return (
    <tr className="d-flex p-0 m-0 table__filterrow">
      <th className="col-2 p-1" scope="col">
        {renderSelect({ filterProp: 'updatedAt', ids: dedupeDates })}
      </th>
      <th className="col-4 p-1" scope="col">
        {renderSelect({ filterProp: 'caseId', ids: dedupeCaseIds })}
      </th>
      <th className="col-4 p-1" scope="col">
        {renderSelect({ filterProp: 'documentId', ids: dedupeDocumentIds })}
      </th>
      <th className="col-2 p-1" scope="col">
        {renderSelect({ filterProp: 'accessStatus', ids: dedupeStatus })}
      </th>
    </tr>
  )
}

export default FilterRow
