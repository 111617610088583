import React, { useState } from 'react'
import Chevron from '../../../../Chevron'
import SubRow from './SubRow'

const TableRow = ({ request }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const { scope, subject } = request.data.payload

  const renderRow = () => (
    <tr key={request.id} className="d-flex p-0 m-0">
      <td onClick={toggle} className="col-2">
        <Chevron isOpen={isOpen} />
        <span>{new Date(request.createdAt).toLocaleDateString()}</span>
      </td>
      <td onClick={toggle} className="col-3">
        {scope.caseId}
      </td>
      <td onClick={toggle} className="col-2">
        {scope.eventId}
      </td>
      <td onClick={toggle} className="col-2">
        {scope.documentId || '*'}
      </td>
      <td onClick={toggle} className="col-1">
        {scope.versionId || '*'}
      </td>
      <td onClick={toggle} className="col-2">
        {subject}
      </td>
    </tr>
  )

  const renderSubRow = () => (isOpen ? <SubRow request={request} /> : null)

  return (
    <>
      {renderRow()}
      {renderSubRow()}
    </>
  )
}

export default TableRow
