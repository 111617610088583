import { MsalAuthProvider, LoginType } from 'react-aad-msal'

const clientId = window._env_.REACT_APP_CLIENT_ID
const redirectUri = window._env_.REACT_APP_REDIRECT_URI
const tenantId = window._env_.REACT_APP_TENANT_ID
const scope = window._env_.REACT_APP_SCOPE

export default new MsalAuthProvider(
  {
    auth: {
      authority: `https://login.microsoftonline.com/${tenantId}`,
      clientId,
      redirectUri,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: [scope],
  },
  {
    loginType: LoginType.Popup,
  }
)
