import React, { useEffect, useState } from 'react'
import { Col, Toast, ToastBody, ToastHeader, Tooltip } from 'reactstrap'

import './../../../../../css/TradeSecrets.css'

const ResponseDisplay = ({ response }) => {
  const [clipboardWriteAllowed, setClipboardWriteAllowed] = useState(false)
  const [copyDocHashTooltipOpen, setcopyDocHashTooltipOpen] = useState(false)
  const [copyDocURLTooltipOpen, setcopyDocUrlTooltipOpen] = useState(false)

  const toggleDocHashTooltip = () => setcopyDocHashTooltipOpen(!copyDocHashTooltipOpen)
  const toggleDocUrlTooltip = () => setcopyDocUrlTooltipOpen(!copyDocURLTooltipOpen)

  useEffect(() => {
    navigator.permissions
      .query({ name: 'clipboard-write' })
      .then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          setClipboardWriteAllowed(true)
        }
      })
      .catch((err) => console.error(err))
  }, [])

  const copyToClipboard = (data) => () => navigator.clipboard.writeText(data)

  const renderCopyDocumentHashToClipboard = () => (
    <div
      id="copyDocumentHash"
      data-clipboard-text={response.documentHash}
      className="copyclipboard"
      onClick={copyToClipboard(response.documentHash)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="copyclipboard__ico">
        <path d="M18.88 4v12.26h-2V6H9.12V4h9.76z" fill="currentColor"></path>
        <path d="M5.12 7.98v12.26h9.76V8l-9.76-.02zm7.75 10.28H7.12V10h5.76l-.01 8.26z" fill="currentColor"></path>
      </svg>
      <Tooltip placement="top" isOpen={copyDocHashTooltipOpen} target="copyDocumentHash" toggle={toggleDocHashTooltip}>
        Copy Document Hash to the Clipboard
      </Tooltip>
    </div>
  )
  const renderCopyDocumentUrlToClipboard = () => (
    <div
      id="copyDocumentURL"
      data-clipboard-text={response.documentURL}
      className="copyclipboard"
      onClick={copyToClipboard(response.documentURL)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="copyclipboard__ico">
        <path d="M18.88 4v12.26h-2V6H9.12V4h9.76z" fill="currentColor"></path>
        <path d="M5.12 7.98v12.26h9.76V8l-9.76-.02zm7.75 10.28H7.12V10h5.76l-.01 8.26z" fill="currentColor"></path>
      </svg>
      <Tooltip placement="top" isOpen={copyDocURLTooltipOpen} target="copyDocumentURL" toggle={toggleDocUrlTooltip}>
        Copy Document URL to the Clipboard
      </Tooltip>
    </div>
  )

  const renderResponseSuccess = () => (
    <div className="p-3 bg-success my-2 rounded">
      <Col>
        <Toast>
          <ToastHeader>Trade Secret Established</ToastHeader>
          <ToastBody>
            <div className="d-flex flex-row">
              <div>
                <span className="col-sm-auto">Document URL:</span>
                <a rel="noopener noreferrer" target="_blank" href={response.data.documentURL}>
                  <span className="pl-2 mb-2 text-muted">{response.data.documentURL?.substring(0, 65)}...</span>
                </a>
              </div>
              {clipboardWriteAllowed ? renderCopyDocumentUrlToClipboard() : ''}
            </div>
            <div className="d-flex flex-row">
              <div>
                <span className="col-sm-auto">Document Hash:</span>
                <span className="mb-2 text-muted">{response.data.documentHash?.substring(0, 55)} </span>
              </div>
              {clipboardWriteAllowed ? renderCopyDocumentHashToClipboard() : ''}
            </div>
          </ToastBody>
        </Toast>
      </Col>
    </div>
  )
  const renderResponseError = () => (
    <div className="p-3 bg-danger my-2 rounded">
      <Col sm={{ size: 8, offset: 2 }}>
        <Toast>
          <ToastHeader>Error</ToastHeader>
          <ToastBody>{response.data.message}</ToastBody>
        </Toast>
      </Col>
    </div>
  )

  return <>{response.isError ? renderResponseError() : renderResponseSuccess()}</>
}

export default ResponseDisplay
