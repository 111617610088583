import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import '../../../../css/TradeSecrets.css'
import { Spinner } from 'reactstrap'
import AccessRequestsTable from './components/AccessRequestsTable'
import ErrorToast from '../components/ErrorToast'
import { getAll } from '../../slices/accessRequestsSlices'

const AccessRequestsView = ({ activeTab }) => {
  const dispatch = useDispatch()
  const { isLoading, error } = useSelector((state) => state.accessRequests)

  useEffect(() => {
    if (activeTab === '3') dispatch(getAll())
  }, [activeTab])

  return (
    <div className="AccessRequestsView mt-3 px-xl-5">
      <ErrorToast error={error} />
      {isLoading ? (
        <Spinner
          color="primary"
          as="span"
          animation="grow"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
          aria-hidden="true"
          className="mt-3"
        />
      ) : (
        <AccessRequestsTable />
      )}
    </div>
  )
}
export default AccessRequestsView
