import { combineReducers } from '@reduxjs/toolkit'

import accessRequestsReducer from '../components/trade-secrets/slices/accessRequestsSlices'
import myAccessRequestsReducer from '../components/trade-secrets/slices/myAccessRequestsSlices'
import tradeSecretsReducer from '../components/trade-secrets/slices/tradeSecretsSlices'

const rootReducer = combineReducers({
  accessRequests: accessRequestsReducer,
  myAccessRequests: myAccessRequestsReducer,
  tradeSecrets: tradeSecretsReducer,
})

export default rootReducer
