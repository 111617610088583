import { createSlice } from '@reduxjs/toolkit'
import { fetchAllTradeSecrets } from '../api'
import { loadingFailed, startLoading } from './utils'

const initialState = {
  error: null,
  events: [],
  isLoading: false,
}

const tradeSecretsSlice = createSlice({
  name: 'tradeSecrets',
  initialState,
  reducers: {
    getAllStart: startLoading,
    getAllSuccess: (state, action) => {
      state.isLoading = false
      state.error = null
      state.events = action.payload
    },
    getAllFailure: loadingFailed,
    resetEvents: (state) => {
      state.events = []
    },
  },
})

export const { getAllSuccess, resetEvents } = tradeSecretsSlice.actions
const { getAllFailure, getAllStart } = tradeSecretsSlice.actions

export default tradeSecretsSlice.reducer

export const getAll = () => async (dispatch) => {
  try {
    dispatch(getAllStart())
    const res = await fetchAllTradeSecrets()
    if (res.isError) {
      return dispatch(getAllFailure(res.data))
    }
    dispatch(getAllSuccess(res.data.items))
  } catch (error) {
    dispatch(getAllFailure(error))
  }
}
