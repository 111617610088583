import { createSlice } from '@reduxjs/toolkit'
import { fetchAllAccessRequests, postApproveForDomain, postApproveForUser, postRevokeForDomain } from '../api'
import { loadingFailed, matchingFilter, startLoading } from './utils'

const initialState = {
  activeFilters: {},
  error: null,
  events: [],
  fetchedEvents: [],
  isLoading: false,
}

const accessRequestsSlice = createSlice({
  name: 'accessRequests',
  initialState,
  reducers: {
    applyActiveFilters: (state) => {
      const filteredEvents = state.fetchedEvents.filter(matchingFilter(state.activeFilters))
      state.events = filteredEvents.length > 0 ? filteredEvents : state.fetchedEvents
    },
    filter: (state, action) => {
      state.events = state.fetchedEvents.filter(matchingFilter(action.payload))
      state.activeFilters = action.payload
    },

    getAllFailure: loadingFailed,
    getAllStart: startLoading,
    getAllSuccess: (state, action) => {
      state.isLoading = false
      state.error = null
      state.fetchedEvents = action.payload
    },

    postFailure: loadingFailed,
    postStart: startLoading,
    postSuccess: (state) => {
      state.error = null
    },

    resetEvents: (state) => {
      state.events = []
    },
  },
})

export const { applyActiveFilters, filter, resetEvents } = accessRequestsSlice.actions
const { getAllFailure, getAllStart, getAllSuccess, postFailure, postStart, postSuccess } = accessRequestsSlice.actions

export default accessRequestsSlice.reducer

export const getAll = () => async (dispatch) => {
  try {
    dispatch(getAllStart())
    const res = await fetchAllAccessRequests()
    if (res.isError) {
      return dispatch(getAllFailure(res.data))
    }
    dispatch(applyActiveFilters())
    dispatch(getAllSuccess(res.data.items))
  } catch (error) {
    dispatch(getAllFailure(error))
  }
}

export const approveAccessRequestForUser = (payload) => async (dispatch) => {
  try {
    dispatch(postStart())
    const res = await postApproveForUser(payload)
    if (res.isError) {
      return dispatch(postFailure(res.data))
    }
    dispatch(postSuccess())
    dispatch(getAll())
  } catch (error) {
    dispatch(getAllFailure(error))
  }
}
export const approveAccessRequestForDomain = (payload) => async (dispatch) => {
  try {
    dispatch(postStart())
    const res = await postApproveForDomain(payload)
    if (res.isError) {
      return dispatch(postFailure(res.data))
    }
    dispatch(postSuccess())
    dispatch(getAll())
  } catch (error) {
    dispatch(getAllFailure(error))
  }
}
export const revokeAccessRequestForDomain = (payload) => async (dispatch) => {
  try {
    dispatch(postStart())
    const res = await postRevokeForDomain(payload)
    if (res.isError) {
      return dispatch(postFailure(res.data))
    }
    dispatch(postSuccess())
    dispatch(getAll())
  } catch (error) {
    dispatch(getAllFailure(error))
  }
}
