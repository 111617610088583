import { getToken } from '../../utils'

export const postTradeSecret = async (data) => {
  try {
    const Authorization = await getToken()
    const formData = new FormData()

    let payload = {}
    for (const name in data) {
      if (name !== 'file') {
        payload[name] = data[name]
      }
    }
    formData.append('file', data.file)
    formData.append('payload', JSON.stringify(payload))

    const response = await fetch(window._env_.REACT_APP_BASEURL + `/v1/ip/trade-secret/event`, {
      method: 'POST',
      headers: {
        Authorization,
        // Content-Type must not be specified or upload won't work
        // 'Content-Type': 'multipart/form-data',
      },
      body: formData,
    })
    const parsedResponse = await response.json()
    return {
      isError: response.status >= 400,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const postAccessRequest = async (data) => {
  try {
    const Authorization = await getToken()

    const response = await fetch(window._env_.REACT_APP_BASEURL + `/v1/ip/tradesecret/accessRequest`, {
      method: 'POST',
      headers: {
        Authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    const parsedResponse = await response.json()
    return {
      isError: response.status >= 400,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const postApproveForUser = async (data) =>
  postApproveAccess({
    ...data,
    level: 'email',
  })

export const postApproveForDomain = async (data) =>
  postApproveAccess({
    ...data,
    level: 'domain',
  })

export const postRevokeForDomain = async (data) => {
  try {
    const Authorization = await getToken()

    data.level = data.level || 'email'
    const url = `${window._env_.REACT_APP_BASEURL}/v1/ip/tradesecret/revokeAccess`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (response.status >= 400) {
      return {
        isError: !response.ok,
        data: await response.json(),
      }
    }
    return {
      isError: false,
      data: null,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

const postApproveAccess = async (data) => {
  try {
    const Authorization = await getToken()

    const url = `${window._env_.REACT_APP_BASEURL}/v1/ip/tradesecret/approveAccess`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (response.status >= 400) {
      return {
        isError: !response.ok,
        data: await response.json(),
      }
    }
    return {
      isError: false,
      data: null,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const fetchAllTradeSecrets = async () => {
  try {
    const Authorization = await getToken()

    const response = await fetch(window._env_.REACT_APP_BASEURL + `/v1/ip/tradesecret`, {
      method: 'GET',
      headers: {
        Authorization,
      },
    })
    let parsedResponse = await response.json()
    const isError = response.status >= 400

    return {
      isError,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const fetchAllAccessRequests = async (lastOnly = true) => {
  try {
    const Authorization = await getToken()

    const response = await fetch(
      window._env_.REACT_APP_BASEURL + `/v1/ip/tradesecret/accessRequests?last-only=${lastOnly}`,
      {
        method: 'GET',
        headers: {
          Authorization,
        },
      }
    )
    let parsedResponse = await response.json()
    const isError = response.status >= 400

    return {
      isError,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const fetchMyAccessRequests = async (email, lastOnly = true) => {
  try {
    const Authorization = await getToken()

    const response = await fetch(
      window._env_.REACT_APP_BASEURL + `/v1/ip/tradesecret/accessRequests?last-only=${lastOnly}`,
      {
        method: 'GET',
        headers: {
          Authorization,
        },
      }
    )
    let parsedResponse = await response.json()
    const isError = response.status >= 400

    if (!isError) {
      const filteredEvents = parsedResponse.items.filter((event) => event.data.payload.subject === email)
      parsedResponse = {
        count: filteredEvents.length,
        items: filteredEvents,
      }
    }
    return {
      isError,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const fetchAccessRequests = async (data) => {
  try {
    const Authorization = await getToken()

    const url = `${window._env_.REACT_APP_BASEURL}/v1/ip/tradesecret/accessRequests/case/${data.caseId}?status=${data.status}`
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization,
      },
    })
    const parsedResponse = await response.json()
    return {
      isError: response.status >= 400,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}

export const requestAuthToken = async (email) => {
  try {
    const url = `${window._env_.REACT_APP_BASEURL}/v1/ip/tradesecret/authToken?email=${encodeURIComponent(email)}`
    const response = await fetch(url, {
      method: 'GET',
    })
    const parsedResponse = await response.json()
    return {
      isError: response.status >= 400,
      data: parsedResponse,
    }
  } catch (error) {
    return {
      isError: true,
      data: error,
    }
  }
}
