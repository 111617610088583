import React from 'react'
import { Col, Toast, ToastHeader, ToastBody } from 'reactstrap'

const ErrorToast = ({ error }) => (
  <>
    {error ? (
      <div className="p-3 bg-danger my-2 rounded error col-sm-10 offset-sm-1">
        <Col sm={{ size: 8, offset: 2 }}>
          <Toast>
            <ToastHeader>Error</ToastHeader>
            <ToastBody>{error.message}</ToastBody>
          </Toast>
        </Col>
      </div>
    ) : null}
  </>
)

export default ErrorToast
