import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { Col, Button, FormGroup, Label, Input, FormFeedback, Spinner, Toast, ToastHeader, ToastBody } from 'reactstrap'
import { requestAuthToken } from '../api'
import ErrorToast from '../holder/components/ErrorToast'

// eslint-disable-next-line
const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const GetAuthTokenForm = () => {
  const [pending, setPending] = useState(false)
  const [error, setError] = useState()
  const [showSuccess, setShowSuccess] = useState(false)

  const { register, handleSubmit, watch, errors, reset } = useForm({
    criteriaMode: 'all',
  })
  const onSubmit = async (data) => {
    setError()
    setPending(true)
    await requestAuthToken(data.email)
      .then((response) => {
        if (response.isError) {
          return setError({ message: response.data.message || 'Please verify the email address is valid' })
        }

        setShowSuccess(true)
        reset()
        return response
      })
      .catch((error) => setError(error))
      .finally(() => setPending(false))
  }

  const renderSuccess = () => {
    if (!showSuccess) {
      return null
    }
    return (
      <div className="p-3 bg-success my-2 rounded">
        <Toast>
          <ToastHeader icon="success">Thank you</ToastHeader>
          <ToastBody>Request succesful. Please check your inbox.</ToastBody>
        </Toast>
      </div>
    )
  }

  return (
    <div className="container">
      <Col sm={{ size: 8, offset: 2 }}>
        {pending ? <Spinner color="primary" /> : ''}
        <ErrorToast error={error} />
        {renderSuccess()}

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Label for="email" sm={{ size: 3 }}>
              Email
            </Label>
            <Col>
              <Input
                valid={!!watch('email') && !errors.email}
                invalid={!!errors.email}
                name="email"
                id="email"
                placeholder="email@example.com"
                innerRef={register({
                  required: 'This field is required',
                  pattern: {
                    value: emailRegEx,
                    message: 'Please enter a valid email addresss',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ messages }) =>
                  Object.entries(messages).map(([type, message]) => <FormFeedback key={type}>{message}</FormFeedback>)
                }
              />
            </Col>
          </FormGroup>

          <Button color="primary" type="submit" disabled={pending}>
            Get new login code by email
          </Button>
        </form>
      </Col>
    </div>
  )
}

export default GetAuthTokenForm
