import React from 'react'
import { Table } from 'reactstrap'
import MessagesRow from './MessagesRow'
import './../css/AuditReport.css'

const MessagesTable = ({ data, title }) => {
  if (data[title].length === 0) {
    return null
  }

  return (
    <div>
      <h5>{title}</h5>

      <Table className="event-table">
        <thead>
          <tr className="font-weight-bold event-name d-flex">
            <th className="col" style={{ padding: '0 15px', border: 0 }}>
              Status
            </th>
            <th className="col" style={{ padding: '0 15px', border: 0 }}>
              Timestamp
            </th>
          </tr>
        </thead>
        <tbody>
          {data[title].map((event, index) => (
            <MessagesRow key={`${title}-${index}`} data={event} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}
export default MessagesTable
