import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Header from './components/Header'
import './css/App.css'
import AuditReport from './components/AuditReport'
import TradeSecretsHolder from './components/trade-secrets/holder/TradeSecretsHolder'
import TradeSecretsConsumer from './components/trade-secrets/consumer/TradeSecretsConsumer'

const App = () => (
  <div className="App">
    <BrowserRouter>
      <Header />
      <div className="body">
        <Route path="/" exact component={AuditReport} />
        <Route path="/trade-secrets/holder" component={TradeSecretsHolder} />
        <Route path="/trade-secrets/consumer" component={TradeSecretsConsumer} />
      </div>
    </BrowserRouter>
  </div>
)

export default App
