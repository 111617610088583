import React from 'react'
import './../css/AuditReport.css'
import EventsTable from './EventsTable'
import MessagesTable from './MessagesTable'

const Audit = ({ data }) => {
  if (!data) {
    return null
  }

  return (
    <div className="mt-5">
      <EventsTable data={data.audit} title="Pre-Publication Events" cols={['EventId', 'WIPO Status', 'Timestamp']} />
      <EventsTable data={data.audit} title="Public Events" cols={['EventId', 'WIPO Status', 'Timestamp']} />
      <EventsTable data={data.audit} title="Trade Secret" cols={['EventId', 'Timestamp']} />

      <MessagesTable data={data.audit} title="Private Messages" />
    </div>
  )
}

export default Audit
