import React from 'react'
import logo from '../logo.svg'
import '../css/Header.css'
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarText } from 'reactstrap'
import { NavLink as RRNavLink, useRouteMatch } from 'react-router-dom'

const getTitle = (isHolderPage, isConsumerPage) => {
  let value = 'Audit'

  if (isHolderPage) {
    value = 'Trade Secret Owner Portal'
  } else if (isConsumerPage) {
    value = 'Trade Secret Consumer Portal'
  }

  document.title = value
  return value
}

const Header = () => {
  const isHolderPage = !!useRouteMatch('/trade-secrets/holder')
  const isConsumerPage = !!useRouteMatch('/trade-secrets/consumer')
  const nodeName = window._env_.REACT_APP_NODE
  const title = getTitle(isHolderPage, isConsumerPage)

  return (
    <div className="header">
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">
            <img alt="logo" className="logo" src={logo} />
          </NavbarBrand>
          <Nav className="mr-auto" navbar>
            {!isConsumerPage && (
              <>
                <NavItem>
                  <NavLink tag={RRNavLink} exact to="/">
                    Audit
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to="/trade-secrets/holder">
                    My Trade Secrets
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <NavbarText>{nodeName}</NavbarText>
        </Navbar>
      </div>
      <div className="d-flex">
        <div className="col-12 title">
          <div className="justify-content-center">{title}</div>
        </div>
      </div>
    </div>
  )
}

export default Header
