import React, { useState } from 'react'
import { Container, Col, Row } from 'reactstrap'
import { Modal, Button, Form } from 'react-bootstrap'

const SubRow = ({ request }) => {
  const [modalUrl, setModalUrl] = useState('')
  const [documentToken, setDocumentToken] = useState('')
  const { accessRequestId, accessStatus, documents, level, scope, subject } = request.data.payload

  const handleClose = () => setModalUrl('')
  const handleShow = (url) => setModalUrl(url)
  const handleShowDocument = (e) => {
    e.preventDefault()
    window.open(modalUrl + documentToken, '_blank')
    setModalUrl('')
    setDocumentToken('')
  }
  const renderDocumentUrls = () => {
    const docs = {}

    // Extract each version id
    documents.forEach((d) => {
      if (!docs[d.documentId]) {
        docs[d.documentId] = []
      }

      docs[d.documentId] = [...docs[d.documentId], d]
    })

    // Sort each version id
    Object.keys(docs).forEach((key) => {
      docs[key].sort((a, b) => a.versionId < b.versionId)
      docs[key] = [docs[key][0]]
    })

    return Object.keys(docs).map((key) => (
      <div key={`${request.id}-${docs[key][0].versionId}`}>
        version:{' '}
        <span
          className="link"
          onClick={() => handleShow(docs[key][0].url)}
        >{`${docs[key][0].documentId}-${docs[key][0].versionId}`}</span>
        <br />
        hash: {docs[key][0].hash}
      </div>
    ))
  }

  return (
    <>
      <Modal show={!!modalUrl} onHide={handleClose}>
        <Form onSubmit={handleShowDocument}>
          <Modal.Header closeButton>
            <Modal.Title>Document access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formToken">
              <Form.Label>Please insert your access token to open the document</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your access token"
                onChange={(e) => setDocumentToken(e.target.value)}
              />
              <Form.Text className="text-muted">This is the token you received by mail</Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Show document
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <tr className="d-flex p-0 m-0 sub-row">
        <td className="col p-0 m-0 pb-3">
          <Container>
            <Row>
              <Col className="col-2 sub-row__key">Notarization Date</Col>
              <Col className="col-10 sub-row__value">{new Date(request.createdAt).toLocaleString()}</Col>
            </Row>
            {level && (
              <Row>
                <Col className="col-2 sub-row__key">Level</Col>
                <Col className="col-10 sub-row__value">{level}</Col>
              </Row>
            )}
            <Row>
              <Col className="col-2 sub-row__key">Documents</Col>
              <Col className="col-10 flex-column sub-row__value sub-row__value--overflow">{renderDocumentUrls()}</Col>
            </Row>
            <Row>
              <Col className="col-2 sub-row__key">Access Request ID</Col>
              <Col className="col-10 sub-row__value">{accessRequestId}</Col>
            </Row>
            <Row>
              <Col className="col-2 sub-row__key">Status</Col>
              <Col className="col-10 sub-row__value">{accessStatus}</Col>
            </Row>
            <Row>
              <Col className="col-2 sub-row__key">Subject</Col>
              <Col className="col-10 sub-row__value">{subject}</Col>
            </Row>
            <Row>
              <Col className="col-2 sub-row__key">Case</Col>
              <Col className="col-10 sub-row__value">{scope.caseId}</Col>
            </Row>
            <Row>
              <Col className="col-2 sub-row__key">Document</Col>
              <Col className="col-10 sub-row__value">{scope.documentId || '*'}</Col>
            </Row>
            <Row>
              <Col className="col-2 sub-row__key">Version</Col>
              <Col className="col-10 sub-row__value">{scope.versionId || '*'}</Col>
            </Row>
          </Container>
        </td>
      </tr>
    </>
  )
}
export default SubRow
