import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap'

import { filter } from '../../../../slices/myAccessRequestsSlices'

const renderSelectOptions = (values) =>
  values.map((value = '*') => (
    <option key={value} value={value}>
      {value}
    </option>
  ))

const uniqDefined = (values) =>
  Array.from(new Set(values))
    .filter((value) => !!value)
    .sort()
const uniq = (values) => Array.from(new Set(values)).sort()

const FilterRow = () => {
  const dispatch = useDispatch()
  const { activeFilters, events } = useSelector((state) => state.myAccessRequests)

  const [dedupeDates, setDedupeDates] = useState([])
  const [dedupeCaseIds, setDedupeCaseIds] = useState([])
  const [dedupeEventIds, setDedupeEventIds] = useState([])
  const [dedupeDocumentIds, setDedupeDocumentIds] = useState([])
  const [dedupeVersionIds, setDedupeVersionIds] = useState([])
  const [dedupeSubjects, setDedupeSubjects] = useState([])

  useEffect(() => {
    setDedupeDates(uniqDefined(events.map((event) => new Date(event.updatedAt).toLocaleDateString())))
    setDedupeCaseIds(uniqDefined(events.map((event) => event.data.payload.scope.caseId)))
    setDedupeEventIds(uniqDefined(events.map((event) => event.data.payload.scope.eventId)))
    setDedupeDocumentIds(uniq(events.map((event) => event.data.payload.scope.documentId)))
    setDedupeVersionIds(uniq(events.map((event) => event.data.payload.scope.versionId)))
    setDedupeSubjects(uniqDefined(events.map((event) => event.data.payload.subject)))
  }, [events])

  const renderSelect = ({ filterProp, ids }) => (
    <Input
      className="p-0 input__lessheight"
      type="select"
      name="select"
      id={`${filterProp}Select`}
      onChange={(event) => dispatch(filter({ ...activeFilters, [filterProp]: event.target.value }))}
    >
      <option key={`${filterProp}everyone`} value={null}></option>
      {renderSelectOptions(ids)}
    </Input>
  )

  return (
    <tr className="d-flex p-0 m-0 table__filterrow">
      <th className="col-2 p-1" scope="col">
        {renderSelect({ filterProp: 'updatedAt', ids: dedupeDates })}
      </th>
      <th className="col-3 p-1" scope="col">
        {renderSelect({ filterProp: 'caseId', ids: dedupeCaseIds })}
      </th>
      <th className="col-2 p-1" scope="col">
        {renderSelect({ filterProp: 'eventId', ids: dedupeEventIds })}
      </th>
      <th className="col-2 p-1" scope="col">
        {renderSelect({ filterProp: 'documentId', ids: dedupeDocumentIds })}
      </th>
      <th className="col-1 p-1" scope="col">
        {renderSelect({ filterProp: 'versionId', ids: dedupeVersionIds })}
      </th>
      <th className="col-2 p-1" scope="col">
        {renderSelect({ filterProp: 'subject', ids: dedupeSubjects })}
      </th>
    </tr>
  )
}

export default FilterRow
